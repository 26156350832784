import { Schedule } from "src/app/core/domain/entities";
import { OutfitterRejectSchedule } from "src/app/core/domain/usecases/outfitter/reject-schedule";
import { HttpClient } from "../../contracts";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";

export class OutfitterRejectScheduleImpl implements OutfitterRejectSchedule {
	constructor(private readonly httpClient: HttpClient, private readonly httpExceptionHandle: HttpExceptionHandle) { }

	async perform(schedule: Schedule, observartion: string = ''): Promise<void> {
		let body = { id: schedule.id, observartion };

		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Scheduling/outfitter/RejectData`,
			body
		});

		this.httpExceptionHandle.handle(httpResponse.status)
	}
}
