import { Notification } from "src/app/core/domain/entities/notification";
import { GetNotifications } from "src/app/core/domain/usecases/notifications/get-notifications";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetNotificationsImpl implements GetNotifications {
	constructor(private readonly httpClient: HttpClient, private readonly httpHandleException: HttpExceptionHandle) {}

	async perform(userId: string): Promise<Notification[]> {
		const httpResponse = await this.httpClient.request({
			method: "get",
			url: `Notification/all/${userId}`,
		});

		if(httpResponse.status === HttpClientResponseStatus.OK){
			return httpResponse.body.map((v: any) => v as Notification);
		}

		this.httpHandleException.handle(httpResponse.status)

		return []
	}
}
