import { GetHttpClient, HttpClientRequest } from "../../contracts";
import { AbstractListingSchedule } from "./abstract-listing-schedule";

export class SalesExecutiveListingSchedulesImpl extends AbstractListingSchedule {
	constructor(protected readonly httpClient: GetHttpClient) {
		super(httpClient)
	}

	protected getRequest(id?:number): HttpClientRequest {
		return {
			url: `Scheduling/get-all-jet`,
			method: "get",
		}
	}
}



