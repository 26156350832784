import {
	Schedule,
} from "src/app/core/domain/entities/schedule";
import { BaseStatus } from "src/app/core/domain/entities/base-status";
import { UnauthorizedException } from "src/app/core/domain/exceptions";
import { CancelingScheduleException } from "src/app/core/domain/exceptions/canceling-schedule-exception";
import { CancelingSchedule } from "src/app/core/domain/usecases/schedule/canceling-schedule";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class CancelingScheduleImpl implements CancelingSchedule {
	constructor(private readonly httpClient: HttpClient) {}
	async perform(schedule: Schedule): Promise<boolean> {

        if(!schedule || !schedule.id || schedule.id < 0){
            throw new CancelingScheduleException('Ops. Esse pedido é inválido')
        }

        if(schedule.status === BaseStatus.CANCELED || schedule.status === BaseStatus.FINISHED){
            throw new CancelingScheduleException('Ops. O pedido já foi cancelado ou finalizado')
        }

		const httpResponse = await this.httpClient.request({
			method: "post",
			url: "Admin/scheduling/change-status",
			body: {
				identity: BaseStatus.CANCELED,
				id: schedule.id,
			},
		});

        switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				return true

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			default:
				throw new CancelingScheduleException()
		}
	}
}
