import React, { createContext, useContext, useState } from "react";
import { User } from "src/app/core/domain/entities";
import { SignOut } from "src/app/core/domain/usecases/auth/signout";

interface AuthContextProps {
	user: User | null;
	logout: () => void;
	addUserToContext: (user: User) => void
}

interface AuthProviderProps {
	signout: SignOut;
}

const AuthContext = createContext<AuthContextProps>({} as any);

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
	const [user, setUser] = useState<User | null>(null);

	const logout = async () => {
		await props.signout.perform();
		setUser(null);
	};

	/**
	 * TODO: Estudar a possibilidade de adicionar o login aqui no contexto
	 * @param user usuário ativo
	 */
	const addUserToContext = (user: User) => {
		setUser(user)
	}

	return (
		<AuthContext.Provider value={{ logout, user, addUserToContext }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
export const useAuthContext = () => useContext(AuthContext);
