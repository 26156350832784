import { PriceNegociation } from "src/app/core/domain/entities/price-negociation";
import {
	UnauthorizedException,
	UnexpectedException,
} from "src/app/core/domain/exceptions";
import { ListingPriceNegociations } from "src/app/core/domain/usecases/price-negociation/listing-price-negociations";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class ListingPriceNegociationsImpl implements ListingPriceNegociations {
	constructor(private httpClient: HttpClient) {}
	async perform(): Promise<PriceNegociation[]> {
		const response = await this.httpClient.request({
			method: "get",
			url: `PriceQuote/get-all`,
		});

		switch (response.status) {
			case HttpClientResponseStatus.OK:
				return response.body.map((v: any) => new PriceNegociation(v));
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException();
			default:
				throw new UnexpectedException();
		}
	}
}
