import { UnauthorizedException, UnexpectedException } from "../../domain/exceptions"
import { NotAllowedException } from "../../domain/exceptions/not-allowed-exception"
import { HttpClientResponseStatus } from "../contracts"

export  class HttpExceptionHandle {
	public handle(status: HttpClientResponseStatus) {
		switch (status) {
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			case HttpClientResponseStatus.BAD_REQUEST:
				throw new UnexpectedException()

			case HttpClientResponseStatus.NOT_ALLOWED:
				throw new NotAllowedException()
		}
	}
}
