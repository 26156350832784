import { Schedule, BaseStatus } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { ConfirmSchedule } from "src/app/core/domain/usecases/schedule";

import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class OutfitterConfirmsScheduleImpl implements ConfirmSchedule {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(schedule: Schedule): Promise<void> {

		// if (schedule.status !==BaseStatusOUTF_PENDING) {
		// 	throw new Error("Esse pedido não pode ser confirmado")
		// }

		const body = {
			"status": BaseStatus.APPROVED,
			"id": schedule.id,
		}

		const scheduleResponse = await this.httpClient.request({
			method: 'put',
			url: `Scheduling/outfitter/change-status`,
			body
		});

		switch(scheduleResponse.status){
			case HttpClientResponseStatus.OK:
			case HttpClientResponseStatus.WITHOUT_CONTENT:
				return
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()
			default:
				throw new UnexpectedException()
		}
	}
}

