import { PriceNegociation } from "src/app/core/domain/entities/price-negociation";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { GetPriceNegociation } from "src/app/core/domain/usecases/price-negociation";

import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetPriceNegociationImpl implements GetPriceNegociation {
	constructor(private httpClient: HttpClient) { }

	async perform(priceNegociationId: number): Promise<PriceNegociation> {
		const response = await this.httpClient.request({
			method: 'get',
			url: `PriceQuote/get-price-quote/${priceNegociationId}`,
		});

		switch (response.status) {
			case HttpClientResponseStatus.OK:
				return new PriceNegociation(response.body[0])
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()
			default:
				throw new UnexpectedException()
		}
	}
}
