
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { GetPixForScheduling } from "src/app/core/domain/usecases/pix/get-pix-for-scheduling";
import { Pix } from "src/app/core/domain/entities/pix";
import { pixMapper } from "../../mappers/pix-mapper";

export class GetPixForSchedulingImpl implements GetPixForScheduling {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(schedulingId: number): Promise<Pix | null> {

		const httpResponse = await this.httpClient.request({
			method: "get",
			url: `/Pix/scheduling/${schedulingId}`,
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				return httpResponse.body.data ? pixMapper(httpResponse.body.data): null
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			default:
				throw new UnexpectedException()
		}

	}
}
