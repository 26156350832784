import { Account } from "../core/domain/entities/account";
import { AccountCacheClient } from "../core/providers/contracts/account-cache-client";

export class LocalstorageAccountCacheClient implements AccountCacheClient {
	private TOKEN_API = "@INFOS_ACCOUNT/DASHBOARDHANGARAR";

	set(account: Account): void {
		localStorage.setItem(this.TOKEN_API, JSON.stringify(account));
	}

	get(): Account | null {
		const item = localStorage.getItem(this.TOKEN_API);
		if (item) {
			const parsed = JSON.parse(item);
			const account = {
				...parsed,
				created: new Date(parsed.created),
				expiration: new Date(parsed.expiration),
			};

			if(account.expiration.getTime() < new Date().getTime()){
				localStorage.removeItem(this.TOKEN_API)
				return null
			}

			return account
		}

		return null;
	}

	remove(): void {
		localStorage.removeItem(this.TOKEN_API)
	}
}
