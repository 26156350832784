import { FuelService } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { ToogleFuelServiceStatus } from "src/app/core/domain/usecases/outfitter/toogle-fuel-service-status";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { FuelServiceMapper } from "../../mappers/fuel-service-mapper";

export class ToogleFuelServiceStatusImpl implements ToogleFuelServiceStatus {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(__fuelService: FuelService): Promise<FuelService> {
		const fuelService = FuelServiceMapper.toFuelService(__fuelService)

		const body = new FormData();
		body.append('status', !fuelService.actived as any);

		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Admin/service/change-status/${fuelService.id}`,
			body
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			case HttpClientResponseStatus.BAD_REQUEST:
				throw new UnexpectedException()
		}

		fuelService.actived = !fuelService.actived
		return fuelService
	}
}
