import { Schedule } from "src/app/core/domain/entities";
import { GetHttpClient, HttpClientRequest } from "../../contracts";
import { AccountCacheClient } from "../../contracts/account-cache-client";
import { AbstractListingSchedule } from "./abstract-listing-schedule";

export class OutfitterListingSchedulesImpl extends AbstractListingSchedule {
	constructor(
		protected readonly httpClient: GetHttpClient,
		private readonly accountCache: AccountCacheClient
	) {
		super(httpClient);
	}

	protected getRequest(id?: number): HttpClientRequest {
		const account = this.accountCache.get();
		return {
			url: `Scheduling/get-scheduling-outfitter/${account?.userId}`,
			method: "get",
		};
	}

	async perform(userId?: number): Promise<Schedule[]> {
		return (await super.perform(userId)).sort(
			(firstSchedule, secondSchedule) =>
				secondSchedule.createdAtId - firstSchedule.createdAtId
		);
	}
}
