import * as http from "src/app/core/providers/contracts/http-client";
import { AxiosInstance, AxiosResponse } from "axios";

export class HttpClientAdapter implements http.HttpClient {
	constructor(private readonly api: AxiosInstance) {}

	async request(
		request: http.HttpClientRequest
	): Promise<http.HttpClientResponse> {
		let response: AxiosResponse
		try {
			switch (request.method) {
				case 'get':
					response = await this.get(request);
					break

				case "post":
					response = await this.post(request);
					break

				case 'delete':
					response = await this.delete(request);
					break

				case 'put':
					response = await this.put(request);
					break

				default:
					return {
						status: 500
					}
			}
		} catch (error) {
			response = error.response || {status: 500}
		}

		return {
			status: response!.status,
			body: response!.data
		}

	}

	private async get(
		request: http.HttpClientRequest
	): Promise<AxiosResponse> {
		return this.api.get(request.url, request.headers);

	}

	private async post(
		request: http.HttpClientRequest
	): Promise<AxiosResponse> {
		return this.api.post(
			request.url,
			request.body,
			request.headers
		);
	}

	private async delete(
		request: http.HttpClientRequest
	): Promise<AxiosResponse> {

		return this.api.delete(
			request.url, {
				data: request.body,
				headers: request.headers
			}
		);

	}

	private async put(
		request: http.HttpClientRequest
	): Promise<AxiosResponse> {
		return await this.api.put(
			request.url,
			request.body,
			request.headers
		);
	}
}
