import { Airport } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { GetAirports } from "src/app/core/domain/usecases/outfitter/get-airports";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetAirportsImpl implements GetAirports{
	constructor(private readonly httpClient: HttpClient) {}

	async perform(outifitterId: number): Promise<Airport[]> {
		const httpResponse = await this.httpClient.request({
			method: "get",
			url: `Outfitter/airports/${outifitterId}`
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				return httpResponse.body.map((v: any) => (v as Airport))

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			default:
				throw new UnexpectedException()
		}

	}

}
