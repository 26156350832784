/**
 * @DependencyInjectionContext
 *
 * Configuração do DI
 * Por enquanto, uma boa solução é usar a contextAPI e fazer os componentes usarem uma factory
 * Vamos avaliar isso mais a frete e ver como melhorar.
 */
import React from "react";
import { DependencyInjectionFactoryProps, InstanceTypeInjection } from "../../contracts/dependency-injection-factory";

interface DependencyInjectionProps {
	get(instance: InstanceTypeInjection): any;
}

const DependencyInjectionContext = React.createContext<DependencyInjectionProps>(
	{} as any
);

export const DependencyInjectionProvider: React.FC<DependencyInjectionFactoryProps> = (
	props
) => {
	const { factory, ...othersProps } = props;

	function get(instance: InstanceTypeInjection): any {
		return factory.create(instance);
	}

	return (
		<DependencyInjectionContext.Provider value={{ get }} {...othersProps}>
			{othersProps.children}
		</DependencyInjectionContext.Provider>
	);
};

export default DependencyInjectionContext;

export const useDi = () => React.useContext(DependencyInjectionContext);
