import { BaseStatus } from "src/app/core/domain/entities";
import {
	UnauthorizedException,
	UnexpectedException,
} from "src/app/core/domain/exceptions";
import { RejectsPriceNegociation } from "src/app/core/domain/usecases/price-negociation";

import {
	HttpClient,
	HttpClientResponse,
	HttpClientResponseStatus,
} from "../../contracts";

export class RejectsPriceNegociationImpl implements RejectsPriceNegociation {
	constructor(private httpClient: HttpClient) {}

	async perform(
		isOfPriceNegociation: number,
		isOnlyPriceNegociation: boolean
	): Promise<void> {
		const body = {
			status: BaseStatus.CANCELED,
			id: isOfPriceNegociation,
		};

		let response: HttpClientResponse;

		if (isOnlyPriceNegociation) {
			const body = {
				status: "CANCELED",
				id: isOfPriceNegociation,
			};

			response = await this.httpClient.request({
				method: "put",
				url: `PriceQuote/change-status`,
				body,
			});
		} else {
			response = await this.httpClient.request({
				method: "put",
				url: `Scheduling/salesExec/change-status`,
				body,
			});
		}

		switch (response.status) {
			case HttpClientResponseStatus.OK:
				return;
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException();
			default:
				throw new UnexpectedException();
		}
	}
}
