import { Outfitter } from "src/app/core/domain/entities";
import {
	UnauthorizedException,
	UnexpectedException,
} from "src/app/core/domain/exceptions";
import { GetOutfitterInfos } from "src/app/core/domain/usecases/outfitter/get-outfitter-infos";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetOutfitterInfosImpl implements GetOutfitterInfos {
	constructor(private readonly httpClient: HttpClient) {}

	async perform(outfitterId: number): Promise<Outfitter> {
		const httpResponse = await this.httpClient.request({
			method: "post",
			url: `Outfitter/get-all`,
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				// FIXME: Esse implementação é temporária. Devemos usar o endpoint que aponta para buscar as infos da revenda
				return httpResponse.body
					.filter((v: any) => v.id === outfitterId)
					.map((v: any) => v as Outfitter)[0];

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException();

			default:
				throw new UnexpectedException();
		}
	}
}
