import axios, {AxiosInstance} from 'axios'
import { LocalstorageAccountCacheClient } from './localstorage-account-cache-client'

export function createAxiosApi(baseURL?: string): AxiosInstance {
	const URL_API = process.env.REACT_APP_URL_API
	const accountLocalStorage = new LocalstorageAccountCacheClient()
	const api = axios.create({baseURL: baseURL || URL_API || ''})

	api.interceptors.request.use((config) => {
		const account = accountLocalStorage.get()

		if(account){
			config.headers['Authorization'] = `Bearer ${account.accessToken}`
		}

		return config
	})
	return api
}
