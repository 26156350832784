import { Outfitter } from "src/app/core/domain/entities";
import { SaveOutfitterInfos } from "src/app/core/domain/usecases/outfitter/save-outfitter-infos";
import { CNPJValidator } from "src/app/core/validators/cnpj-validator";
import { PhoneNumberValidator } from "src/app/core/validators/phone-number-validator";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient } from "../../contracts";

export class SaveOutfitterInfosImpl implements SaveOutfitterInfos {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly httpExceptionHandle: HttpExceptionHandle,
		private readonly cnpjValidator: CNPJValidator,
		private readonly phoneNumberValidator: PhoneNumberValidator
	) {}

	async perform(outfitter: Outfitter): Promise<void> {

		if(!this.cnpjValidator.validate(outfitter.register!)){
			throw new Error("Informe um CNPJ válido")
		}

		if(!this.phoneNumberValidator.validate(outfitter.phone!)){
			throw new Error("Informe um número de telefone válido")
		}

		if(!outfitter.companyName || outfitter.companyName === ''){
			throw new Error("Informe o nome da empresa")
		}

		const body = {
			identityId: outfitter.identity,
			email: outfitter.email,
			phoneNumber: outfitter.phone,
			companyName: outfitter.companyName,
			register: outfitter.register,
		};


		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Outfitter/edit/${outfitter.id}`,
			body
		});

		this.httpExceptionHandle.handle(httpResponse.status);
	}
}
