import { ChangeStatusAirport } from "src/app/core/domain/usecases/airport/change-status-airport";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient } from "../../contracts";

export class ChangeStatusAirportImpl implements ChangeStatusAirport {
	constructor(
		protected readonly httpClient: HttpClient,
		protected readonly handleExecption: HttpExceptionHandle
	) {}

	async perform(
		outfitterId: number,
		airportId: number,
		status: boolean,
		isAttendingMessage?:string
	): Promise<void> {

		const body = {
			idOutfitter: outfitterId,
			idAirport: airportId,
			isAttending: status,
			isAttendingMessage
		};

		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Outfitter/attending-fuel`,
			body,
		});

		this.handleExecption.handle(httpResponse.status);
	}
}
