export enum UserRole  {
	SALES_EXECUTIVE='SalesExec',
	ADMIN='Admin',
	OPERATOR='Operator',
	OUTFFITER='Outfitter',
}





