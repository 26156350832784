import { BaseStatus, FuelServiceName, Schedule, UserRole } from "../entities";
import { EventItem } from "../entities/event-item";
import { isAValidDate } from "./is-a-valid-date";

export function createScheduleHistoryEvents(schedule: Schedule): EventItem[] {
	let historyEvent: EventItem[] = [];

	if (isAValidDate(schedule.createdAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido criado",
				UserRole.OPERATOR,
				new Date(schedule.createdAt!)
			)
		);
	}

	if (isAValidDate(schedule.operFinishedAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido finalizado pelo piloto",
				UserRole.OPERATOR,
				new Date(schedule.operFinishedAt!)
			)
		);
	}

	if (isAValidDate(schedule.operCanceledAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido cancelado pelo piloto",
				UserRole.OPERATOR,
				new Date(schedule.operCanceledAt!)
			)
		);
	}

	if (
		!isAValidDate(schedule.operCanceledAt) &&
		isAValidDate(schedule.canceledAt)
	) {
		historyEvent.push(
			new EventItem(
				"Pedido cancelado",
				UserRole.OPERATOR,
				new Date(schedule.canceledAt!)
			)
		);
	}

	if (isAValidDate(schedule.outfCounterOfferAt)) {
		historyEvent.push(
			new EventItem(
				"Contraoferta enviada",
				UserRole.OUTFFITER,
				new Date(schedule.outfCounterOfferAt!)
			)
		);
	}

	if (isAValidDate(schedule.outfFinishedAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido finalizado pela revenda",
				UserRole.OUTFFITER,
				new Date(schedule.outfFinishedAt!)
			)
		);
	}

	if (isAValidDate(schedule.outfApprovedAt)) {
		historyEvent.push(
			new EventItem(
				"Horário aprovado",
				UserRole.OUTFFITER,
				new Date(schedule.outfApprovedAt!)
			)
		);
	}

	if (isAValidDate(schedule.operApprovedAt)) {
		historyEvent.push(
			new EventItem(
				"Contraoferta aceita",
				UserRole.OPERATOR,
				new Date(schedule.operApprovedAt!)
			)
		);
	}

	if (isAValidDate(schedule.execTimeAt)) {
		let title =
			schedule.proposalType === 1
				? "Oferta aceita"
				: "Contraoferta enviada";

		historyEvent.push(
			new EventItem(
				title,
				UserRole.SALES_EXECUTIVE,
				new Date(schedule.execTimeAt!)
			)
		);
	}

	let eventTitle = "";
	let madeByWho:
		| UserRole.SALES_EXECUTIVE
		| UserRole.OPERATOR
		| UserRole.OUTFFITER;

	switch (schedule.status) {
		case BaseStatus.EXEC_PENDING:
			eventTitle = "Aguardando executivo de vendas";
			madeByWho = UserRole.SALES_EXECUTIVE;
			break;
		case BaseStatus.OPER_PENDING:
			eventTitle = "Aguardando resposta do piloto";
			madeByWho = UserRole.OPERATOR;
			break;
		case BaseStatus.OUTF_PENDING:
			eventTitle = "Aguardando revenda";
			madeByWho = UserRole.OUTFFITER;
			break;
		default:
			eventTitle = "";
	}

	if (eventTitle !== "") {
		historyEvent.push(new EventItem(eventTitle, madeByWho!));
	}

	historyEvent = historyEvent.sort((a, b) => {
		if (a.eventOccurAt() && b.eventOccurAt()) {
			return a.eventOccurAt()!.getTime() - b.eventOccurAt()!.getTime();
		}

		if (!a.eventOccurAt()) {
			return new Date().getTime() - b.eventOccurAt()!.getTime();
		}

		return a.eventOccurAt()!.getTime() - new Date().getTime();
	});

	const brScheduleHistory = historyEvent.filter(
		(v) => !v.madeBySalesOutifitter
	);
	const outfitterScheduleHistory = historyEvent.filter(
		(v) => v.madeBySalesOutifitter
	);

	historyEvent = brScheduleHistory.concat(outfitterScheduleHistory);

	if (schedule.serviceName === FuelServiceName.AVGAS) {
		historyEvent.forEach((v) => (v.madeByWho = UserRole.OUTFFITER));
	}

	return historyEvent;
}
