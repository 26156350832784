import { UserRole } from "./user-role";

export class EventItem {
	constructor(
		private eventTitle: string,
		private _madeByWho:
			| UserRole.OPERATOR
			| UserRole.OUTFFITER
			| UserRole.SALES_EXECUTIVE,
		private _eventOccurAt?: Date
	) {}

	public title(): string {
		return this.eventTitle;
	}

	public eventOccurAt(): Date | undefined {
		return this._eventOccurAt;
	}

	public madeBySalesExecutive(): boolean {
		return this._madeByWho === UserRole.SALES_EXECUTIVE;
	}

	public madeBySalesOperator(): boolean {
		return this._madeByWho === UserRole.OPERATOR;
	}

	public madeBySalesOutifitter(): boolean {
		return this._madeByWho === UserRole.OUTFFITER;
	}

	set madeByWho(
		_madeByWho:
			| UserRole.OPERATOR
			| UserRole.OUTFFITER
			| UserRole.SALES_EXECUTIVE
	) {
		this._madeByWho = _madeByWho;
	}
}
