import { FuelServiceName, Schedule } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { MakeCounterOfferSchedule } from "src/app/core/domain/usecases/schedule/make-counter-offer-schedule";

import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class OutfitterMakeCounterOfferScheduleImpl implements MakeCounterOfferSchedule {
	constructor(private readonly httpClient: HttpClient) { }


	async perform(schedule: Schedule, counterOfferPrice: number): Promise<void> {

		if (schedule.serviceName !== FuelServiceName.AVGAS) {
			throw new Error("Você não pode fazer contraoferta para esse pedido")
		}

		if (counterOfferPrice === undefined || counterOfferPrice === null || counterOfferPrice <= 0 || isNaN(counterOfferPrice)) {
			throw new Error("O valor da contaoferta não pode ser menor ou igual a zero")
		}

		const body = {
			"price": (counterOfferPrice * schedule.quantity!),
		}

		const scheduleResponse = await this.httpClient.request({
			method: 'put',
			url: `Scheduling/outfitter/negotiate/${schedule.id}`,
			body
		});



		if (scheduleResponse.status === HttpClientResponseStatus.UNAUTHORIZED) {
			throw new UnauthorizedException()
		} else if (scheduleResponse.status !== HttpClientResponseStatus.OK) {
			throw new UnexpectedException()
		}


	}


}

