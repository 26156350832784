import { FuelProvider } from "src/app/core/domain/entities";
import { LoadFuelProviders } from "src/app/core/domain/usecases/outfitter/load-fuel-providers";

export class LoadFuelProvidersImpl implements LoadFuelProviders{

	async perform(): Promise<FuelProvider[]> {
		return [
			new FuelProvider('BR Distribuidora'),
			new FuelProvider('Shell'),
			new FuelProvider('BP'),
			new FuelProvider('Brandeira Branca')
		]
	}
}
