export function toLocalDate(date: Date | string): Date {
	const timezone = new Date().getTimezoneOffset()

	const __date = typeof date === 'string' ? new Date(date) : date

	if (timezone > 0) {
		__date.setMinutes(__date.getMinutes() - timezone)
	} else if (timezone < 0) {
		__date.setMinutes(__date.getMinutes() + (timezone * -1))
	}

	return __date
}
