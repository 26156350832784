import { Pix } from "../../domain/entities/pix";

export function pixMapper(bodyPix: any): Pix {
	return new Pix({
		...bodyPix,
		createdAt: new Date(bodyPix.createdAt),
		confirmedAt: bodyPix.confirmedAt
			? new Date(bodyPix.confirmedAt)
			: bodyPix.confirmedAt,
		isUsedAt: bodyPix.isUsedAt
			? new Date(bodyPix.isUsedAt)
			: bodyPix.isUsedAt,
	});
}
