import { BaseStatus, UserRole } from "../entities";
import { EventItem } from "../entities/event-item";
import { PriceNegociation } from "../entities/price-negociation";
import { isAValidDate } from "./is-a-valid-date";

export function createPriceNegociationHistoryEvents(
	priceNegociation: PriceNegociation
): EventItem[] {
	let historyEvent: EventItem[] = [];

	if (isAValidDate(priceNegociation.createdAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido criado",
				UserRole.OPERATOR,
				new Date(priceNegociation.createdAt!)
			)
		);
	}

	if (isAValidDate(priceNegociation.acceptedAt)) {
		historyEvent.push(
			new EventItem(
				"Negociação aceita",
				UserRole.SALES_EXECUTIVE,
				new Date(priceNegociation.acceptedAt!)
			)
		);
	}

	if (isAValidDate(priceNegociation.approvedAt)) {
		historyEvent.push(
			new EventItem(
				"Contraoferta aceita",
				UserRole.OPERATOR,
				new Date(priceNegociation.approvedAt!)
			)
		);
	}

	if (isAValidDate(priceNegociation.finishedAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido finalizado",
				UserRole.OPERATOR,
				new Date(priceNegociation.finishedAt!)
			)
		);
	}

	if (isAValidDate(priceNegociation.negotiatedAt)) {
		historyEvent.push(
			new EventItem(
				"Contraoferta enviada",
				UserRole.SALES_EXECUTIVE,
				new Date(priceNegociation.negotiatedAt!)
			)
		);
	}

	if (isAValidDate(priceNegociation.canceledAt)) {
		historyEvent.push(
			new EventItem(
				"Pedido cancelado",
				UserRole.OPERATOR,
				new Date(priceNegociation.canceledAt!)
			)
		);
	}

	let eventTitle = "";
	let madeByWho:
		| UserRole.SALES_EXECUTIVE
		| UserRole.OPERATOR
		| UserRole.OUTFFITER;

	switch (priceNegociation.status) {
		case BaseStatus.EXEC_PENDING:
			eventTitle = "Aguardando executivo de vendas";
			madeByWho = UserRole.SALES_EXECUTIVE;
			break;
		case BaseStatus.OPER_PENDING:
			eventTitle = "Aguardando resposta do piloto";
			madeByWho = UserRole.OPERATOR;
			break;
		case BaseStatus.OUTF_PENDING:
			eventTitle = "Aguardando revenda";
			madeByWho = UserRole.OUTFFITER;
			break;
		default:
			eventTitle = "";
	}

	if (eventTitle !== "") {
		historyEvent.push(new EventItem(eventTitle, madeByWho!));
	}

	historyEvent = historyEvent.sort((a, b) => {
		if (a.eventOccurAt() && b.eventOccurAt()) {
			return a.eventOccurAt()!.getTime() - b.eventOccurAt()!.getTime();
		}

		if (!a.eventOccurAt()) {
			return new Date().getTime() - b.eventOccurAt()!.getTime();
		}

		return a.eventOccurAt()!.getTime() - new Date().getTime();
	});

	return historyEvent;
}
