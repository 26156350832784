import { FuelService } from "src/app/core/domain/entities";
import { AirportService } from "src/app/core/domain/entities/airport-service";
import { HangarService } from "src/app/core/domain/entities/hangar-service";
import { HelipadService } from "src/app/core/domain/entities/helipad-services";
import { LoadOutfitterServices } from "src/app/core/domain/usecases/outfitter/load-outfitter-services";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { FuelServiceMapper } from "../../mappers/fuel-service-mapper";

export class LoadOutfitterServicesImpl implements LoadOutfitterServices {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly httpExceptionHandle: HttpExceptionHandle
	) {}

	async perform(outfitterId: number): Promise<{
		fuelServices: FuelService[];
		hangarServices: AirportService[];
		helipadServices: AirportService[];
	} | undefined> {
		const httpResponse = await this.httpClient.request({
			method: "get",
			url: `Outfitter/services/${outfitterId}`,
		});

		if (httpResponse.status === HttpClientResponseStatus.OK) {
			const fuelServices = httpResponse.body.fuelServices.map(
				FuelServiceMapper.toFuelService
			);

			const hangarServices = httpResponse.body.hangarServices.map(
				(v: any) => v as HangarService
			);

			const helipadServices = httpResponse.body.helipadServices.map(
				(v: any) => v as HelipadService
			);

			return {
				fuelServices,
				hangarServices,
				helipadServices,
			};
		}

		this.httpExceptionHandle.handle(httpResponse.status);
		return
	}
}
