
import { PixAlreadyConfirmedError, UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { Pix } from "src/app/core/domain/entities/pix";
import { ConfirmPixPayment } from "src/app/core/domain/usecases/pix";

export class ConfirmPixPaymentImpl implements ConfirmPixPayment {

	constructor(private readonly httpClient: HttpClient) { }

	async perform(pix: Pix): Promise<void> {
		if(pix.confirmedAt){
			throw new PixAlreadyConfirmedError()
		}
		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Pix/Confirm`,
			body: {
				schedulingId: pix.schedulingId
			}
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				return
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()
			default:
				throw new UnexpectedException()
		}
	}
}
