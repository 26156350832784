import { UserRole } from "./user-role";

export enum UserStatus {
	ACTIVE = 1,
}

export class User {
	name?: string;
	email?: string;
	role?: UserRole;
	id?: string;
	actived?: boolean;
	archived?: boolean;
	companyName?: string;
	firebase?: string;
	fullName?: string;
	notifyNews?: boolean;
	notifySales?: boolean;
	phoneNumber?: string;
	registerNumber?: number;
	status?: number;
	userId?: number;

	constructor(props: Partial<User>){
		Object.assign(this, props)
	}

	public isOutfitter(): boolean{
		return this.role === UserRole.OUTFFITER
	}

	public isSalesExecutive(): boolean{
		return this.role === UserRole.SALES_EXECUTIVE
	}
}



export class Outfitter extends User {
	phone?: string;
	register?: string;
	identity?: string;

	constructor(props: Partial<Outfitter>){
		super(props)
		this.phone = props.phone
		this.register = props.register
		this.identity = props.identity
		this.role = UserRole.OUTFFITER
	}
};
