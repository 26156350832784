import { InvalidFuelPriceException, UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { UpdateFuelPriceCash, UpdateFuelPriceInstallment } from "src/app/core/domain/usecases/outfitter/update-fuel-price";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export abstract class AbstractUpdateFuelPrice implements UpdateFuelPriceCash, UpdateFuelPriceInstallment {

	constructor(protected readonly httpClient: HttpClient) { }

	async perform(fuelServiceId: number, priceInCash: number): Promise<void> {
		if(priceInCash <= 0) {
			throw new InvalidFuelPriceException()
		}

		const body = new FormData();
		body.append('price', `${priceInCash}`);

		const url = this.getUrlToUpdate(fuelServiceId)

		const httpResponse = await this.httpClient.request({
			method: "put",
			url,
			body
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			case HttpClientResponseStatus.BAD_REQUEST:
				throw new UnexpectedException()
		}

	}

	protected abstract getUrlToUpdate(fuelServiceId: number): string
}
