import { Schedule, BaseStatus } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { CheckoutSchedule } from "src/app/core/domain/usecases/schedule/checkout-schedule";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class OutfitterCheckoutScheduleImpl implements CheckoutSchedule {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(schedule: Schedule): Promise<void> {
		if (schedule.status !== BaseStatus.APPROVED) {
			throw new Error("Esse pedido não pode ser finalizado")
		}

		const body = {
			"status": BaseStatus.FINISHED,
			"id": schedule.id,
		}

		const scheduleResponse = await this.httpClient.request({
			method: 'put',
			url: `Scheduling/outfitter/change-status`,
			body
		});

		if (scheduleResponse.status === HttpClientResponseStatus.UNAUTHORIZED) {
			throw new UnauthorizedException()
		} else if (scheduleResponse.status !== HttpClientResponseStatus.OK) {
			throw new UnexpectedException()
		}
	}
}
