import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { NotAllowedException } from "src/app/core/domain/exceptions/not-allowed-exception";
import { KeepAllFuelPrices } from "src/app/core/domain/usecases/outfitter/keep-all-fuel-prices";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class KeepAllFuelPricesImpl implements KeepAllFuelPrices {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(fuelServiceIds: number[]): Promise<void> {
		let body = { "ids": fuelServiceIds };


		const httpResponse = await this.httpClient.request({
			method: "post",
			url: `Service/fuel/confirm/price/all`,
			body
		});


		switch (httpResponse.status) {
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			case HttpClientResponseStatus.BAD_REQUEST:
				throw new UnexpectedException()

			case HttpClientResponseStatus.NOT_ALLOWED:
				throw new NotAllowedException()
		}
	}
}
