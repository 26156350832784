export enum RoleNumber {
	OPERATOR = 1,
	SALES_EXECUTIVE = 2,
	OUTFITTER = 3
}

export class Negociation {
	value?: number
	proposal?: number
	timestamp?: string
	role?: RoleNumber
	title?: string

	constructor(readonly props: Omit<Negociation, 'title'>) {
		Object.assign(this, props)

		if (this.role === RoleNumber.OPERATOR) {
			this.title = "Oferta enviada"
		} else if (this.role === RoleNumber.SALES_EXECUTIVE) {
			this.title = "Contaoferta enviada"
		} else if (this.role === RoleNumber.OUTFITTER){
			this.title = "Contaoferta enviada"
		}
	}

}
