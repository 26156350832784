
import React from "react";
import { useLottie } from "lottie-react";

import loadingAirplan2 from "./animations/loading-airplan-2.json";
import loadingAirplan1 from "./animations/loading-airplan.json";
import start0 from "./animations/stars-0.json";
import start1 from "./animations/stars-1.json";
import start2 from "./animations/stars-2.json";
import start3 from "./animations/stars-3.json";
import start4 from "./animations/stars-4.json";
import start5 from "./animations/stars-5.json";
import success from "./animations/success.json";
import error from "./animations/error.json";
import airplainAndClouds from "./animations/airplain-and-cloud.json";
import whiteAirplane from "./animations/white-airplane.json";

export type AnimationAsset =
	| "airplain-1"
	| "airplain-2"
	| "airplain-and-coulds"
	| "start0"
	| "start1"
	| "start2"
	| "start3"
	| "start4"
	| "start5"
	| "white-airplane"
	| "success"
	| "error";

const RenderAnimation = (props: { animationAsset: AnimationAsset }) => {
	let animationData;

	switch (props.animationAsset) {
		case "airplain-1":
			animationData = loadingAirplan1;
			break;

		case "airplain-2":
			animationData = loadingAirplan2;
			break;

		case "start0":
			animationData = start0;
			break;

		case "start1":
			animationData = start1;
			break;

		case "start2":
			animationData = start2;
			break;

		case "start3":
			animationData = start3;
			break;

		case "start4":
			animationData = start4;
			break;

		case "start5":
			animationData = start5;
			break;

		case "success":
			animationData = success;
			break;

		case "error":
			animationData = error;
			break;

		case "airplain-and-coulds":
			animationData = airplainAndClouds;
			break;

		case 'white-airplane':
			animationData = whiteAirplane;
			break;

		default:
			animationData = loadingAirplan1;
	}

	const options = {
		animationData,
		loop: true,
		autoplay: true,
	};

	const { View } = useLottie(options);
	return View;
};

type OwnProps = {
	width?: number;
	height?: number;
	animation?: AnimationAsset;
};

const Animated: React.FC<OwnProps> = ({
	width = 100,
	height = 100,
	animation,
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				alignSelf: "stretch",
			}}
		>
			<div
				style={{
					height,
					width,
				}}
			>
				<RenderAnimation animationAsset={animation || "airplain-1"} />
			</div>
		</div>
	);
};

export default React.memo(Animated);
