import { User } from "src/app/core/domain/entities";
import * as exceptions from "src/app/core/domain/exceptions";
import {
	SigninWithEmailAndPassword,
	SigninWithEmailAndPasswordParams,
} from "src/app/core/domain/usecases/auth";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { AccountCacheClient } from "../../contracts/account-cache-client";

export class SigninWithEmailAndPasswordImpl
	implements SigninWithEmailAndPassword {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly accountCache: AccountCacheClient
	) {}

	async perform(params: SigninWithEmailAndPasswordParams): Promise<User> {
		if (params.email === "") {
			throw new exceptions.InvalidEmailException();
		}

		if (params.password === "") {
			throw new exceptions.InvalidPasswordException();
		}

		const response = await this.httpClient.request({
			url: "Auth/login",
			body: {
				email: params.email,
				password: params.password,
			},
			method: "post",
		});

		switch (response.status) {
			case HttpClientResponseStatus.OK:
				const { user, ...other } = response.body;
				const __user = new User(user)

				if(!__user.isOutfitter() && !__user.isSalesExecutive()){
					throw new exceptions.CannotAccessException()
				}

				this.accountCache.set({...other, userId: __user.userId})

				return __user

			case HttpClientResponseStatus.BAD_REQUEST:
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new exceptions.InvalidCredentialsException(
					response.body?.messagePT || response.body?.message
				);

			default:
				throw new exceptions.UnexpectedException(
					response.body?.messagePT || response.body?.message
				);
		}
	}
}
