/**
 * TODO: Verificar se compensa mapear cada objeto para sua respectiva classe.
 * Isso pode adicionar complexidade a mais no cliente.
 * Talvez não seja uma boa ideia.
 * Ainda vou pensar sobre isso. By Zannan
 */
import { FuelServiceName } from "./fuel-service";
import { Negociation } from "./negociation";
import { BaseStatus } from "./base-status";
import { EventItem } from "./event-item";
import { createScheduleHistoryEvents } from "../factories/create-schedule-history-events";

export type ScheduleStatus = BaseStatus

export class Schedule {
	id?: number;
	airplanetId?: number;
	airplanePrefix?: string;
	airplaneModel?: string;
	airplaneManufacture?: string;
	responsibleName?: string;
	responsibleRegister?: string;
	outfitterName?: string;
	outfitterPhone?: string;
	operatorId?: number;
	operatorName?: string;
	operatorPhone?: string;
	outfitterId?: number;
	serviceId?: number;
	serviceType?: number;
	serviceName?: FuelServiceName;
	serviceDescription?: string;
	provider?: string;
	icaoCode?: string;
	timezone?: string;
	status?: ScheduleStatus;
	execAct?: false;
	local?: string;
	airportCity?: string;
	observation?: string;
	observationOutfitter?: string;
	quantity?: number;
	passQuantity?: number;
	facilities?: string;
	proposalType?: number;
	price?: number;
	brPoints?: number;
	paymentWay?: string;
	report?: string;
	hngrName?: string;
	isHelipad?: false;
	outfitter?: string;
	paymentStatus?: number;
	paymentAuthCode?: string;
	execApproved?: false;
	feedback?: number;
	negociations?: Negociation[];
	execSales?: string
	outfCounterOfferAt?: Date | null;
	outfFinishedAt?: Date | null;
	outfApprovedAt?: Date | null;
	outfRejectedAt?: Date | null;
	operApprovedAt?: Date | null;
	operCanceledAt?: Date | null;
	operFinishedAt?: Date | null;
	appointment?: Date | string;
	appointmentOut?: Date | string;
	approvedAt?: Date | string;
	canceledAt?: Date | string;
	finishedAt?: Date | string;
	createdAt?: Date | string;
	execTimeAt?: Date | string;


	eventsHistory?: EventItem[];

	constructor(private readonly props: Omit<Schedule, "eventsHistory">) {
		Object.assign(this, this.props);

		this.eventsHistory = createScheduleHistoryEvents(this)
	}

	get createdAtId(): number{
		if(typeof this.createdAt !== 'string'){
			return this.createdAt!.getTime()
		}

		return new Date(this.createdAt).getTime()
	}

	public hasOffer(): boolean {
		return this.proposalType === 1 || this.proposalType === 2;
	}

	public wasCanceled(): boolean {
		return (
			this.canceledAt !== null &&
			this.canceledAt !== undefined &&
			this.canceledAt !== "1900-01-01T00:00:00"
		);
	}

	public wasFinshided(): boolean {
		return (
			this.finishedAt !== null &&
			this.finishedAt !== undefined &&
			this.finishedAt !== "1900-01-01T00:00:00"
		);
	}

	public salesExecutiveHadSentCountOffer(): boolean {
		return (
			this.execTimeAt !== null &&
			this.execTimeAt !== undefined &&
			this.execTimeAt !== "1900-01-01T00:00:00" &&
			this.proposalType === 2
		);
	}

	public unitPrice(): number {
		if (!this.price) {
			throw new Error("O preço e a quantidade precisam ser informados");
		}

		if (this.price === 0 || this.quantity === 0) {
			throw new Error("O preço e/ou a quantidade não podem ser zero");
		}

		if (this.price < 0) {
			throw new Error(
				"O preço e/ou a quantidade não podem ser negativos"
			);
		}

		if(!this.quantity){
			return this.price
		}

		const price = (this.price! / this.quantity!) * this.quantity!;

		if (isNaN(price)) {
			throw new Error("O preço unitário não é válido");
		}

		if(price < 0){
			return (price * -1)
		}


		return price;
	}

	public get idWithDigit(): string {
		return `${this.id}.${this.validatorDigit()}`;
	}

	private validatorDigit(): string {
		let code = `${this.id}`;
		let length = code.length;
		let parity = length % 2;
		let sum = 0;

		for (let i = length - 1; i >= 0; i--) {
			let digit = parseInt(code.charAt(i));
			if (i % 2 === parity) {
				digit *= 2;
			}

			if (digit > 9) {
				digit -= 9;
			}

			sum += digit;
		}

		return `${sum % 10}`;
	}
}
