import { Schedule } from "src/app/core/domain/entities";
import {
	UnauthorizedException,
	UnexpectedException,
} from "src/app/core/domain/exceptions";
import { ListingSchedules } from "src/app/core/domain/usecases/schedule/listing-schedules";
import {
	GetHttpClient,
	HttpClientRequest,
	HttpClientResponseStatus,
} from "../../contracts";

export abstract class AbstractListingSchedule implements ListingSchedules {
	constructor(protected readonly httpClient: GetHttpClient) {}

	async perform(userId?: number): Promise<Schedule[]> {
		const clientRequest = this.getRequest(userId);
		const scheduleListReponse = await this.httpClient.request(clientRequest);
		
		switch (scheduleListReponse.status) {
			case HttpClientResponseStatus.OK:
				return scheduleListReponse.body
					.map((v: any) => new Schedule(v))
					.filter((v: Schedule) => v.serviceType === 1)							

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException();

			default:
				throw new UnexpectedException();
		}
	}

	/**
	 * Retorna uma request que deve ser chamada pelo httpClient
	 * Essa request é usada para criar uma chamada à api
	 */
	protected abstract getRequest(userId?: number): HttpClientRequest;
}
