import React, { useState } from "react";
import ConfirmModal from "./modal";

type OptionsParams = {
	title: string;
	message: string;
	value?: any;
	variant?: "danger" | "info" | "success" | "warning";
};

type ConfirmContextProps = {
	useInfo: (params: OptionsParams) => Promise<unknown>;
	useDanger: (params: OptionsParams) => Promise<unknown>;
	useSuccess: (params: OptionsParams) => Promise<unknown>;
	useWarning: (params: OptionsParams) => Promise<unknown>;
};

const Context = React.createContext<ConfirmContextProps>({} as any);

export const ConfirmProvider: React.FC = (props) => {
	const promisesRef =
		React.useRef<{
			resolve: (value?: unknown) => void;
			reject: () => void;
		}>();

	const [optionsParams, setOptionsParams] =
		useState<OptionsParams | null>(null);

	const useInfo = (params: OptionsParams) => {
		setOptionsParams({ ...params, variant: "info" });
		return new Promise((resolve, reject) => {
			promisesRef.current = { resolve, reject };
		});
	};

	const useDanger = (params: OptionsParams) => {
		setOptionsParams({ ...params, variant: "danger" });
		return new Promise((resolve, reject) => {
			promisesRef.current = { resolve, reject };
		});
	};

	const useSuccess = (params: OptionsParams) => {
		setOptionsParams({ ...params, variant: "success" });
		return new Promise((resolve, reject) => {
			promisesRef.current = { resolve, reject };
		});
	};

	const useWarning = (params: OptionsParams) => {
		setOptionsParams({ ...params, variant: "warning" });
		return new Promise((resolve, reject) => {
			promisesRef.current = { resolve, reject };
		});
	};

	const handleConfirm = () => {
		if (promisesRef.current && promisesRef.current.resolve) {
			promisesRef.current.resolve(optionsParams?.value || true);
		}
		setOptionsParams(null);
	};

	const handleClose = () => {
		if (promisesRef.current && promisesRef.current.resolve) {
			promisesRef.current.resolve(false);
		}
		setOptionsParams(null);
	};

	return (
		<Context.Provider
			value={{ useInfo, useDanger, useSuccess, useWarning }}
		>
			{optionsParams && (
				<ConfirmModal
					style={{ zIndex: 40000000 }}
					title={optionsParams.title}
					message={optionsParams.message}
					onConfirm={handleConfirm}
					onClose={handleClose}
					show={optionsParams !== null}
					color={optionsParams.variant}
				/>
			)}
			{props.children}
		</Context.Provider>
	);
};

export default Context;

export const useConfirms = () => React.useContext(Context);
