import { Schedule } from "src/app/core/domain/entities";
import { Negociation } from "src/app/core/domain/entities/negociation";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { GetSchedule } from "src/app/core/domain/usecases/schedule/get-schedule";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetScheduleImpl implements GetSchedule{
	constructor(private readonly httpClient: HttpClient) {}

	async perform(id: number): Promise<Schedule> {

		const scheduleResponse = await this.httpClient.request({
			method: 'get',
			url: `Scheduling/${id}`,
		});

		const negociationResponse = await this.httpClient.request({
			method: 'get',
			url: `Admin/scheduling/negotiation/${id}`,
		});


		switch (scheduleResponse.status) {
			case HttpClientResponseStatus.OK:
				const schedule = new Schedule(scheduleResponse.body)

				if(negociationResponse.status === HttpClientResponseStatus.OK){
					schedule.negociations = negociationResponse.body.reverse().map((v: any) =>  new Negociation(v))
				}

				return schedule

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			default:
				throw new UnexpectedException()
		}

	}
}

