import { SigninWithEmailAndPasswordImpl } from "src/app/core/providers/usecases/auth/signin-with-email-and-password-impl";
import { OutfitterListingSchedulesImpl } from "src/app/core/providers/usecases/schedule/outfitter-listing-schedules-impl";
import { CancelingScheduleImpl } from "src/app/core/providers/usecases/schedule/canceling-schedule-impl";
import { createAxiosApi } from "src/app/infrastructure/api";
import { HttpClientAdapter } from "src/app/infrastructure/http-client-adapter";
import { LocalstorageAccountCacheClient } from "src/app/infrastructure/localstorage-account-cache-client";
import {
	DependencyInjectionFactory,
	InstanceTypeInjection,
} from "src/app/ui/contracts/dependency-injection-factory";
import {
	GetScheduleImpl,
	SalesExecutiveListingSchedulesImpl,
} from "src/app/core/providers/usecases/schedule";
import { OutfitterConfirmsScheduleImpl } from "src/app/core/providers/usecases/schedule/outfitter-confirms-schedule-impl";
import { OutfitterCheckoutScheduleImpl } from "src/app/core/providers/usecases/schedule/outfitter-checkout-schedule-impl";
import { OutfitterAcceptOfferScheduleImpl } from "src/app/core/providers/usecases/schedule/outfitter-accept-offer-schedule-impl";
import { OutfitterMakeCounterOfferScheduleImpl } from "src/app/core/providers/usecases/schedule/outfitter-make-counter-offer-schedule-impl";
import { GetAirportsFuelImpl } from "src/app/core/providers/usecases/outfitter/get-airport-fuels-impl";
import { ToogleFuelServiceStatusImpl } from "src/app/core/providers/usecases/outfitter/toogle-fuel-service-status-impl";
import { UpdateFuelPriceCashImpl } from "src/app/core/providers/usecases/outfitter/update-fuel-price-cash-impl";
import { UpdateFuelPriceInInstallmentImpl } from "src/app/core/providers/usecases/outfitter/update-fuel-price-in-installment";
import { GetAirportsImpl } from "src/app/core/providers/usecases/outfitter/get-airports-impl";
import { KeepAllFuelPricesImpl } from "src/app/core/providers/usecases/outfitter/keep-fuel-prices-impl";
import { OutfitterRejectScheduleImpl } from "src/app/core/providers/usecases/outfitter/reject-schedule-impl";
import { HttpExceptionHandle } from "src/app/core/providers/commons/http-exception-handle";
import { GetNotificationsImpl } from "src/app/core/providers/usecases/notifications/get-notifications-impl";
import { GetOutfitterInfosImpl } from "src/app/core/providers/usecases/outfitter/get-outfitter-infos";
import { SaveOutfitterInfosImpl } from "src/app/core/providers/usecases/outfitter/save-outiftter-infos-impl";
import { CNPJValidatorImpl } from "src/app/utils/validators/cnpj-validator-impl";
import { PhoneNumberValidatorImpl } from "src/app/utils/validators/phone-number-validator";
import { LoadOutfitterServicesImpl } from "src/app/core/providers/usecases/outfitter/load-outfitter-services-impl";
import { LoadFuelProvidersImpl } from "src/app/core/providers/usecases/outfitter/load-fuel-providers-impl";
import { SaveFuelServiceInfosImpl } from "src/app/core/providers/usecases/fuel-service/save-fuel-service-infos";
import { ChangeStatusAirportImpl } from "src/app/core/providers/usecases/airport/change-status-airport-impl";
import { ListingPriceNegociationsImpl } from "src/app/core/providers/usecases/price-negociation/listing-negociations-impl";
import { GetPriceNegociationImpl } from "src/app/core/providers/usecases/price-negociation/get-price-negociation-impl";
import {
	RejectsPriceNegociationImpl,
	SalesExecutiveAcceptPriceNegociationImpl,
} from "src/app/core/providers/usecases/price-negociation";
import { SalesExecutiveSendsCounterOfferImpl } from "src/app/core/providers/usecases/price-negociation/sends-counter-offer-impl";
import {
	ConfirmPixPaymentImpl,
	GetPixForSchedulingImpl,
} from "src/app/core/providers/usecases/pix";
import { GetCurrentNegociatedPriceImpl } from "src/app/core/providers/usecases/operator/get-current-negociated-price-impl";

export class DiFactory implements DependencyInjectionFactory {
	create<T extends any>(instance: string): T {
		const httpClient = new HttpClientAdapter(createAxiosApi());
		const httpClientPIX = new HttpClientAdapter(
			createAxiosApi(process.env.REACT_APP_URL_API_PIX)
		);
		const httpExceptionHandle = new HttpExceptionHandle();

		switch (instance as InstanceTypeInjection) {
			case "SigninWithEmailAndPassword":
				return new SigninWithEmailAndPasswordImpl(
					httpClient,
					new LocalstorageAccountCacheClient()
				) as T;

			case "ListingSchedules":
				return new OutfitterListingSchedulesImpl(
					httpClient,
					new LocalstorageAccountCacheClient()
				) as T;

			case "GetSchedule":
				return new GetScheduleImpl(httpClient) as T;

			case "CancelingSchedule":
				return new CancelingScheduleImpl(httpClient) as T;

			case "ConfirmSchedule":
				return new OutfitterConfirmsScheduleImpl(httpClient) as T;

			case "CheckoutSchedule":
				return new OutfitterCheckoutScheduleImpl(httpClient) as T;

			case "AcceptOfferSchedule":
				return new OutfitterAcceptOfferScheduleImpl(httpClient) as T;

			case "MakeCounterOfferSchedule":
				return new OutfitterMakeCounterOfferScheduleImpl(
					httpClient
				) as T;

			case "GetAirportsFuel":
				return new GetAirportsFuelImpl(httpClient) as T;

			case "GetAirports":
				return new GetAirportsImpl(httpClient) as T;

			case "ToogleFuelServiceStatus":
				return new ToogleFuelServiceStatusImpl(httpClient) as T;

			case "UpdateFuelPriceCash":
				return new UpdateFuelPriceCashImpl(httpClient) as T;

			case "UpdateFuelPriceInInstallment":
				return new UpdateFuelPriceInInstallmentImpl(httpClient) as T;

			case "KeepAllFuelPrices":
				return new KeepAllFuelPricesImpl(httpClient) as T;

			case "OutfitterRejectSchedule":
				return new OutfitterRejectScheduleImpl(
					httpClient,
					new HttpExceptionHandle()
				) as T;

			case "GetNotifications":
				return new GetNotificationsImpl(
					httpClient,
					httpExceptionHandle
				) as T;

			case "GetOutfitterInfos":
				return new GetOutfitterInfosImpl(httpClient) as T;

			case "SaveOutfitterInfos":
				return new SaveOutfitterInfosImpl(
					httpClient,
					new HttpExceptionHandle(),
					new CNPJValidatorImpl(),
					new PhoneNumberValidatorImpl()
				) as T;

			case "LoadOutfitterServices":
				return new LoadOutfitterServicesImpl(
					httpClient,
					new HttpExceptionHandle()
				) as T;

			case "LoadFuelProviders":
				return new LoadFuelProvidersImpl() as T;

			case "SaveFuelServiceInfos":
				return new SaveFuelServiceInfosImpl(
					httpClient,
					new HttpExceptionHandle()
				) as T;

			case "ChangeStatusAirport":
				return new ChangeStatusAirportImpl(
					httpClient,
					new HttpExceptionHandle()
				) as T;

			case "ListingPriceNegociations":
				return new ListingPriceNegociationsImpl(httpClient) as T;

			case "GetPriceNegociation":
				return new GetPriceNegociationImpl(httpClient) as T;

			case "SalesExecutiveListingSchedules":
				return new SalesExecutiveListingSchedulesImpl(httpClient) as T;

			case "SalesExeutiveAcceptPriceNegocation":
				return new SalesExecutiveAcceptPriceNegociationImpl(
					httpClient
				) as T;

			case "RejectsPriceNegociation":
				return new RejectsPriceNegociationImpl(httpClient) as T;

			case "SalesExecutiveSendsCounterOffer":
				return new SalesExecutiveSendsCounterOfferImpl(httpClient) as T;

			case "GetPixForScheduling":
				return new GetPixForSchedulingImpl(httpClientPIX) as T;

			case "ConfirmPixPayment":
				return new ConfirmPixPaymentImpl(httpClientPIX) as T;

			case "GetCurrentNegociatedPrice":
				return new GetCurrentNegociatedPriceImpl(
					httpClient,
					new HttpExceptionHandle()
				) as T;

			default:
				throw new Error("Instância indefinida");
		}
	}
}
