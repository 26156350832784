import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "../ui/scss/style.scss";

import { AuthProvider } from "../ui/contexts/auth";
import { createSignOutImpl } from "./factories/create-signout";
import { LocalstorageAccountCacheClient } from "../infrastructure/localstorage-account-cache-client";
import { DependencyInjectionProvider } from "../ui/contexts/dependency-injection";
import { DiFactory } from "./factories/di-factory";
import { ConfirmProvider } from "../ui/componentes/confirms";
import { UIUtilsProvider } from "../ui/contexts/ui-utils-context";

const TheLayout = React.lazy(() => import("../ui/containers/TheLayout"));
const Login = React.lazy(() => import("../ui/pages/login"));

const App = () => {
	const localStorageCache = new LocalstorageAccountCacheClient();
	localStorageCache.get();


	return (
		<UIUtilsProvider>
			<DependencyInjectionProvider factory={new DiFactory()}>
				<AuthProvider signout={createSignOutImpl()}>
					<ConfirmProvider>
						<HashRouter>
							<React.Suspense fallback={true}>
								<Switch>
									<Route
										exact
										path="/login"
										render={(props) => <Login {...props} />}
									/>

									<Route
										path="/"
										name="Home"
										render={(props) => (
											<TheLayout {...props} />
										)}
									/>
								</Switch>
							</React.Suspense>
						</HashRouter>
					</ConfirmProvider>
				</AuthProvider>
			</DependencyInjectionProvider>
		</UIUtilsProvider>
	);
};

export default App;
