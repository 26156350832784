export class Pix {
	id: string;
	createdAt: string | Date;
	isUsedAt: Date | null;
	confirmedAt: Date | null;
	payload: string;
	userId: string;
	prefix: string;
	schedulingId: number;
	city: string;
	price: number;
	liters: number;

	constructor(private readonly props: Partial<Pix>) {
		Object.assign(this, this.props);
	}

	public pixWasConfirmed(): boolean {
		return this.confirmedAt !== null && this.confirmedAt !== undefined;
	}

	public pixWasUsed(): boolean {
		return this.isUsedAt !== null && this.isUsedAt !== undefined;
	}

	public pixIsPending(): boolean {
		return !this.pixWasConfirmed() && !this.pixWasUsed();
	}
}
