export type HttpClientRequest = {
	url: string
	body?: any,
	headers?: any
	method: 'post' | 'get' | 'delete' | 'put' | 'options'
}

export enum HttpClientResponseStatus {
	OK = 200,
	WITHOUT_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDEN = 403,
	NOT_FOUND = 404,
	SERVER_ERROR = 500,
	NOT_ALLOWED = 405
}


export type HttpClientResponse = {
	body?: any,
	headers?: any
	method?: 'post' | 'get' | 'delete' | 'put' | 'options'
	status: HttpClientResponseStatus
}


export interface GetHttpClient{
	request(request: HttpClientRequest): Promise<HttpClientResponse>
}

export interface PostHttpClient{
	request(request: HttpClientRequest): Promise<HttpClientResponse>
}

export interface HttpClient extends PostHttpClient, GetHttpClient {}
