import { HttpClient } from "../../contracts";
import { AbstractUpdateFuelPrice } from "./update-fuel-price";

export class UpdateFuelPriceInInstallmentImpl extends AbstractUpdateFuelPrice {
	constructor(protected readonly httpClient: HttpClient) {
		super(httpClient)
	}

	protected getUrlToUpdate(fuelServiceId: number): string {
		return `Service/fuel/edit/installment/${fuelServiceId}`
	}
}
