import React, { createContext, useContext, useState } from "react";
import Animated from "../../componentes/animated";

interface UIUtilsContextProps {
	showLoading: () => void;
	hideLoading: () => void;
}

const UIUtilsContext = createContext<UIUtilsContextProps>({} as any);

export const UIUtilsProvider: React.FC = (props) => {
	const [loading, setLoading] = useState<boolean>(false);

	const showLoading = () => {
		setLoading(true);
	};

	const hideLoading = () => {
		setLoading(false);
	};

	return (
		<UIUtilsContext.Provider
			value={{ showLoading, hideLoading }}
			{...props}
		>
			{props.children}
			{loading && (
				<div
					style={{
						flex: 1,
						display: 'flex',
						width: "100%",
						height: "100vh",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 99999,
						backgroundColor: "rgba(0,0,0,0.6)",
					}}
				>
					<Animated animation="white-airplane" height={200} width={200}/>
				</div>
			)}
		</UIUtilsContext.Provider>
	);
};

export default UIUtilsContext;
export const useUIUtils = () => useContext(UIUtilsContext);
