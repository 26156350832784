import { toLocalDate } from "src/app/ui/utils/to-local-date";
import {
	ContactEmail,
	ContactPhone,
	FuelProvider,
	FuelService,
} from "../../domain/entities";

export class FuelServiceMapper {
	static toFuelService(entity: any): FuelService {
		return {
			...entity,
			provider: new FuelProvider(entity.provider),
			priceInCashLastModification: toLocalDate(
				entity.priceInInstallmentLastModification
			),
			priceInInstallmentLastModification: toLocalDate(
				entity.priceInInstallmentLastModification
			),
			contactEmail: entity.contactEmail.map(
				(v: any) => new ContactEmail(v)
			),
			contactPhone: entity.contactPhone.map(
				(v: any) => new ContactPhone(v)
			),
		} as FuelService;
	}

	static toRequestJSON(fuelService: FuelService) {
		return {
			availibility: fuelService.actived,
			description: fuelService.description,
			fuelType: fuelService.fuelName,
			provider: fuelService.provider.getName(),
			priceInCash: fuelService.priceInCash,
			priceInInstallment: fuelService.priceInInstallment,
			email01: fuelService.contactEmail[0].name,
			phone01: fuelService.contactPhone[0].phone,
			email02: fuelService.contactEmail[1].name,
			phone02: fuelService.contactPhone[1].phone,
		};
	}
}
