import { createPriceNegociationHistoryEvents } from "../factories/create-price-negociation-history-event";
import { BaseStatus } from "./base-status";
import { EventItem } from "./event-item";

export class PriceNegociation {
	aeronave: string
	aeroporto: string
	approvedAt: Date | null
	canceledAt: Date | null
	createdAt: Date | null
	finishedAt: Date | null
	negotiatedAt: Date | null
	acceptedAt: Date | null
	id: number
	initalPrice: number
	excSales: string
	operador: string
	price: number
	resposavel:string
	servico: string
	status: BaseStatus
	airplaneId: number
	airportId: number
	baseIcao: string
	city: string
	finalPrice: number
	genericServiceId: number
	observation: string
	offerPrice: number
	operatorUsrId: number
	prefix: string
	responsibleName:string
	timezone: string

	eventsHistory?: EventItem[];

	constructor(private readonly props: Omit<PriceNegociation, "eventsHistory">) {
		Object.assign(this, this.props);

		this.eventsHistory = createPriceNegociationHistoryEvents(this)

		if(!this.timezone){
			this.timezone = "0"
		}
	}
}
