import { BaseStatus } from "src/app/core/domain/entities";
import {
	UnauthorizedException,
	UnexpectedException,
} from "src/app/core/domain/exceptions";
import { SalesExeutiveAcceptPriceNegocation } from "src/app/core/domain/usecases/price-negociation";
import {
	HttpClient,
	HttpClientResponse,
	HttpClientResponseStatus,
} from "../../contracts";

export class SalesExecutiveAcceptPriceNegociationImpl
	implements SalesExeutiveAcceptPriceNegocation
{
	constructor(private httpClient: HttpClient) {}

	async perform(
		priceNegociationId: number,
		isOnlyPriceNegociation: boolean,
		currentOfferPrice: number
	): Promise<void> {
		let response: HttpClientResponse;
		if (isOnlyPriceNegociation) {
			const body = {
				status: BaseStatus.FINISHED,
				id: priceNegociationId,
			};

			response = await this.httpClient.request({
				method: "put",
				url: `PriceQuote/change-status`,
				body: body,
			});

		} else {
			const body = {
				price: currentOfferPrice,
			};

			response = await this.httpClient.request({
				method: "put",
				url: `Scheduling/no-counter-offer/${priceNegociationId}`,
				body: body,
			});
		}

		switch (response.status) {
			case HttpClientResponseStatus.OK:
				return;

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException();
			default:
				throw new UnexpectedException();
		}
	}
}
