import { NotHasNegociatedPriceException } from "src/app/core/domain/exceptions/not-has-negociated-price-exception";
import { GetCurrentNegociatedPrice } from "src/app/core/domain/usecases/operator/get-current-negociated-price";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class GetCurrentNegociatedPriceImpl
	implements GetCurrentNegociatedPrice
{
	constructor(
		private readonly httpClient: HttpClient,
		private readonly httpHandleException: HttpExceptionHandle
	) {}

	async perform(currentNegociatedPriceRequest: {
		airportBaseIcao: string;
		fuelServiceName: string;
		airplanePrefix: string;
		userId: string | number
	}): Promise<{ currentNegociatedPrice: number }> {
		const { airportBaseIcao, fuelServiceName, airplanePrefix, userId } =
			currentNegociatedPriceRequest;
		const response = await this.httpClient.request({
			url: `Service/Scheduling/getDeduction?userId=${userId}&baseIcao=${airportBaseIcao}&prefix=${airplanePrefix}&fuelType=${fuelServiceName}`,
			method: "get",
		});

		if (response.status === HttpClientResponseStatus.OK) {
			const { price: currentNegociatedPrice } = response.body;
			if(currentNegociatedPrice === null){
				throw new NotHasNegociatedPriceException()
			}
			return { currentNegociatedPrice };
		}

		this.httpHandleException.handle(response.status);
		return {} as any;
	}
}
