import React from "react";
import {
	CButton,
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CModalFooter,
} from "@coreui/react";

type OwnProps = {
	onConfirm: (...args: any) => void;
	title: string;
	message: string;
};

const ConfirmModal: React.FC<CModal & OwnProps> = (props) => {
	const { onConfirm, title, message, ...other } = props;

	return (
		<CModal {...other}>
			<CModalHeader closeButton>
				<CModalTitle>{title}</CModalTitle>
			</CModalHeader>
			<CModalBody>{message}</CModalBody>
			<CModalFooter>
				{props.color === "danger" && (
					<CButton
						color="secondary"
						onClick={() => (props.onClose ? props.onClose() : null)}
					>
						Fechar
					</CButton>
				)}

				<CButton
					color={props.color}
					onClick={() => (onConfirm ? onConfirm() : null)}
				>
					{props.color === "danger" ? "Confirmar" : "Ok"}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(ConfirmModal);
