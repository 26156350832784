import { FuelProvider } from "./fuel-provider";
import { GenericService } from "./generic-serivce";

export enum FuelServiceName {
	AVGAS = "AVGAS",
	JET_A1 = "JET A-1",
	JET_A1_PLUS = "JET A-1 Plus",
}

export type FuelService = GenericService & {
	fuelName: FuelServiceName;
	priceInCash: number;
	priceInInstallment: number;
	provider: FuelProvider;
	unit: string;
	currency: string;
	lastModify: Date;
	airport: string
	priceInCashLastModification: Date;
	priceInInstallmentLastModification: Date;
};




