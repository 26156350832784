import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/main/App";
import { icons } from "./app/assets/icons";

import { Provider } from "react-redux";
import store from "./coreui-store";

(React as any).icons = icons;



ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
