
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { FuelService, FuelServiceName } from "src/app/core/domain/entities";
import { GetAirportsFuels } from "src/app/core/domain/usecases/outfitter/get-airport-fuels";
import { HttpClient, HttpClientResponseStatus } from "../../contracts";
import { FuelServiceMapper } from "../../mappers/fuel-service-mapper";


export class GetAirportsFuelImpl implements GetAirportsFuels {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(outifitterId: number, airportId: number): Promise<FuelService[]> {

		const formData = new FormData();
		formData.append('outf', `${outifitterId}`);
		formData.append('airport', `${airportId}`);

		const httpResponse = await this.httpClient.request({
			method: "post",
			url: `Service/outffiter/airport`,
			body: formData
		});

		switch (httpResponse.status) {
			case HttpClientResponseStatus.OK:
				const fuelServices = httpResponse.body.fuelServices.map(FuelServiceMapper.toFuelService) as FuelService[]

				return fuelServices.filter(v => (v.fuelName !== FuelServiceName.JET_A1 && v.fuelName !== FuelServiceName.JET_A1_PLUS))

			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()

			default:
				throw new UnexpectedException()
		}

	}
}
