import { SignOut } from "src/app/core/domain/usecases/auth/signout";
import { AccountCacheClient } from "../../contracts/account-cache-client";

export class SignOutImpl implements SignOut{
	constructor(private readonly accountCacheClient: AccountCacheClient){ }

	async perform(): Promise<void> {
		this.accountCacheClient.remove()
	}

}
