import { FuelService } from "src/app/core/domain/entities";
import { SaveFuelServiceInfos } from "src/app/core/domain/usecases/fuel-service/save-fuel-service-infos";
import { HttpExceptionHandle } from "../../commons/http-exception-handle";
import { HttpClient } from "../../contracts";
import { FuelServiceMapper } from "../../mappers/fuel-service-mapper";

export class SaveFuelServiceInfosImpl implements SaveFuelServiceInfos {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly httpExceptionHandle: HttpExceptionHandle
	) {}

	async perform(fuelService: FuelService): Promise<void> {
		const body = FuelServiceMapper.toRequestJSON(fuelService);

		if (body.email01 === "") {
			throw new Error("Informe um email primário");
		}

		if (body.phone01 === "") {
			throw new Error("Informe um telefone primário");
		}

		const httpResponse = await this.httpClient.request({
			method: "put",
			url: `Service/fuel/edit/${fuelService.id}`,
			body,
		});

		this.httpExceptionHandle.handle(httpResponse.status);
	}
}
