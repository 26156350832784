import { BaseStatus } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { SalesExecutiveSendsCounterOffer } from "src/app/core/domain/usecases/price-negociation/sends-counter-offer";
import { HttpClient, HttpClientResponse, HttpClientResponseStatus } from "../../contracts";


export class SalesExecutiveSendsCounterOfferImpl implements SalesExecutiveSendsCounterOffer {
	constructor(private httpClient: HttpClient) { }

	async perform(priceNegociationId: number, counterOfferPrice: number, isOnlyNegociation: boolean, quantityOfLiters?:number): Promise<void> {

		let response: HttpClientResponse

		const finalPrice = (isOnlyNegociation || !quantityOfLiters) ? counterOfferPrice : (quantityOfLiters! * counterOfferPrice)

		if (isOnlyNegociation) {
			const body = {
				"status": BaseStatus.OPER_PENDING,
				"id": priceNegociationId,
				"price": finalPrice
			}

			response = await this.httpClient.request({
				method: 'put',
				url: `PriceQuote/negotiate`,
				body
			});

		} else {
			const body = {
				"price": finalPrice
			}

			response = await this.httpClient.request({
				method: 'put',
				url: `Scheduling/salesExec/negotiate/${priceNegociationId}`,
				body
			});
		}


		switch (response.status) {
			case HttpClientResponseStatus.OK:
				return;
			case HttpClientResponseStatus.UNAUTHORIZED:
				throw new UnauthorizedException()
			default:
				throw new UnexpectedException()
		}
	}
}
