import {  BaseStatus, FuelServiceName, Schedule } from "src/app/core/domain/entities";
import { UnauthorizedException, UnexpectedException } from "src/app/core/domain/exceptions";
import { AcceptOfferSchedule } from "src/app/core/domain/usecases/schedule/accept-offer-schedule";

import { HttpClient, HttpClientResponseStatus } from "../../contracts";

export class OutfitterAcceptOfferScheduleImpl implements AcceptOfferSchedule {
	constructor(private readonly httpClient: HttpClient) { }

	async perform(schedule: Schedule): Promise<void> {

		if (schedule.status !== BaseStatus.OUTF_PENDING || schedule.serviceName !== FuelServiceName.AVGAS) {
			throw new Error("Você não pode aceitar a oferta para esse pedido")
		}

		if (schedule.proposalType === 0) {
			throw new Error("Não existe oferta para esse pedido")
		}

		if (schedule.proposalType === 2) {
			throw new Error("Uma contra oferta já foi enviada")
		}

		let price = schedule.unitPrice()
		const body = {
			price
		}

		const scheduleResponse = await this.httpClient.request({
			method: 'put',
			url: `Scheduling/no-counter-offer/${schedule.id}`,
			body
		});



		if (scheduleResponse.status === HttpClientResponseStatus.UNAUTHORIZED) {
			throw new UnauthorizedException()
		} else if (scheduleResponse.status !== HttpClientResponseStatus.OK) {
			throw new UnexpectedException()
		}


	}


}

